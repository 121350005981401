<template>
  <div v-if="user">
    <div class="text-center text-h6 mt-4">七天內變動記錄</div>
    <div class="pa-4">
      <div class="text-center" v-if="!historys.length">目前無記錄</div>
      <v-expansion-panels accordion v-else>
        <v-expansion-panel v-for="(row, i) in historys" :key="i">
          <v-expansion-panel-header>
            {{ row.createdAt }} - {{ type(row) }}
          </v-expansion-panel-header>
          <v-expansion-panel-content class="font-small">
            <div>
              變動記錄: {{ row.value }} {{ row.type == "ticket" ? "張" : "顆" }}
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import login from "@/mixins/login";
export default {
  name: "BallHistory",
  mixins: [login],
  data: () => ({
    historys: [],
  }),
  async created() {},
  methods: {
    async getHistory() {
      try {
        this.$vloading.show();
        let payload = { store: this.store, token: this.token };
        let { data } = await this.axios.post(`/getBallHistory`, payload);
        this.historys = data;
      } catch (error) {
        alert(error);
      } finally {
        this.$vloading.hide();
      }
    },
    type(row) {
      switch (true) {
        case row.type == "point":
          return "彈珠變動";
        case row.type == "ticket":
          return "彩票變動";
      }
    },
  },
  watch: {
    user(val) {
      if (val) this.getHistory();
    },
  },
};
</script>

<style lang="scss" scoped>
.font-small {
  font-size: 15px;
}
</style>
